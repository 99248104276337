<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Expense Types </h2>
          <div>
            <router-link :to="{name:'app.expense.type.create'}">
              <button type="button" class="btn btn-primary glow  mb-1"><span class=""><i class='bx bx-plus '></i></span>
                <span class="align-middle ml-25">Add New</span></button>
            </router-link>
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-lg-12">

            <div class="users-list-filter px-1 my-2 py-2 border rounded">
              <div>
                <div class="row align-items-center ">
                  <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                    <label>Show Per Page</label>
                    <VueMultiselect v-model="selectedPagination" class="" :options="pagination" :close-on-select="true"
                                    label="name" track-by="name" :show-labels="false"/>
                  </div>

                </div>
                <div class="row pt-2">
                  <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                    <input v-model="getExpenseTypesParams.name" class="form-control search-product-input-element"
                           type="text"
                           placeholder="Search a expense category by name">
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                    <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                            @click="applyExpenseTypeFilter(null)">Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="users-list-table">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table id="users-list-datatable" class="table">
                      <thead>
                      <tr>
                        <th class="position-relative" :style="{width:'25%'}">TYPES NAME
                          <div class="position-absolute sorting-up-button">
                            <i class='bx bx-chevron-up'></i>
                          </div>
                          <div class="position-absolute sorting-down-button">
                            <i class='bx bx-chevron-down'></i>
                          </div>
                        </th>
                        <th class="position-relative" :style="{width:'25%'}">CATEGORY NAME
                          <div class="position-absolute sorting-up-button">
                            <i class='bx bx-chevron-up'></i>
                          </div>
                          <div class="position-absolute sorting-down-button">
                            <i class='bx bx-chevron-down'></i>
                          </div>
                        </th>
                        <th class="position-relative" :style="{width:'5%'}">ACTION
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(expenseType) in expenseTypes" :key="expenseType.id">
                        <td>{{ expenseType.name }}</td>
                        <td v-if="expenseType.expense_category">{{ expenseType.expense_category.name }}</td>
                        <td>
                          <div class="d-flex align-items-top justify-content-between">
                            <div>
                              <div class="cursor-pointer" :style="{height:'18px'}"
                                   @click="getSingleExpenseType(expenseType.id)" data-toggle="modal"
                                   data-target="#expenseTypeDetails">
                                <i class='bx bx-show'></i>
                              </div>
                            </div>
                            <router-link class="text-decoration-none"
                                         :to="{name:'app.expense.type.edit',params:{id:expenseType.id}}">
                              <div :style="{height:'18px'}">
                                <i class='bx bx-edit'></i>
                              </div>
                            </router-link>
                            <div class="text-decoration-none cursor-pointer">
                              <div data-toggle="modal" data-target="#deleteModal" :style="{height:'18px'}">
                                <i class='bx bx-x'></i>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div class="col-md-12 d-flex justify-content-end">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ExpenseTypeModal :expense-type="this.expenseType"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import ExpenseTypeModal from "@/views/backEnd/settings/ExpenseType/includes/ExpenseTypeModal";

export default {
  name: "ExpenseTypeList",
  components: {
    AppLayout,
    ExpenseTypeModal
  },
  mixins: [ShowToastMessage],
  data() {
    return {
      getExpenseTypesParams: {
        name: '',
        expense_category_id: '',
        order_by_id: 'desc',
        with_relation: ['expenseCategory'],
        paginate: 1,
        pagination: '',
        page: ''
      },
      selectedPagination: {
        value: '',
        name: 'Default'
      },

      expenseType: {},

    }

  },
  watch: {
    selectedPagination: function (pagination) {
      this.getExpenseTypesParams.pagination = pagination.value;
    },
  },
  computed: {
    ...mapGetters({
      expenseTypes: 'appExpenseTypes/expenseTypes',
      paginateLinks: 'appExpenseTypes/paginateLinks',
    }),
    pagination() {
      return [
        {value: this.$store.getters['appSettings/settingDefaultPagination'].value, name: 'Default'},
        {value: 25, name: '25 Entries'},
        {value: 50, name: '50 Entries'},
        {value: 100, name: '100 Entries'}
      ];
    },
  },
  methods: {
    ...mapActions({
      getExpenseTypes: 'appExpenseTypes/getExpenseTypes',
      getExpenseType: 'appExpenseTypes/getExpenseType',
      getSettings: 'appSettings/getSettings',

    }),
    async getExpenseTypesList(expenseTypesParam) {
      await this.getExpenseTypes(expenseTypesParam).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      })
    },
    async getSingleExpenseType(id) {
      const params = {
        id: id,
        params: this.getExpenseTypesParams,
      };

      await this.getExpenseType(params)
          .then((response) => {
            if (response && response.status && response.status === 200) {
              this.expenseType = this.$store.getters['appExpenseTypes/expenseType'];
            }

            if (response && response.message && response.status !== 200) {
              this.showToastMessage(response);
            }

          });
    },
    async applyExpenseTypeFilter(pageNumber) {
      this.getExpenseTypesParams.page = pageNumber;
      await this.getExpenseTypesList(this.getExpenseTypesParams);
    },

  },

  mounted() {
    this.getExpenseTypesList(this.getExpenseTypesParams);
  }

}
</script>

<style scoped>
.badge {
  display: inline-block;
  padding: 3px 3px !important;
  font-size: 10px !important;
  font-weight: 400;
  line-height: 1 !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: baseline !important;
  border-radius: 0.375rem !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media only screen and (max-width: 1337px) and (min-width: 320px) {
  .table {
    white-space: nowrap !important;
  }
}
</style>
