<template>
  <div class="modal fade " id="expenseTypeDetails" tabindex="-1" role="dialog"
       aria-labelledby="warehouseDetailsModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title white" id="">Expense Type Details</h5>
          <button @click="resetExpenseType" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-striped">
            <tbody>
            <tr>
              <th class="col-3">Name</th>
              <th class="col-9">{{ name }}</th>
            </tr>
            <tr>
              <th class="col-3">Expense Category</th>
              <th class="col-9">{{ expense_category }}</th>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer border-0 py-1">
          <button @click="resetExpenseType" type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpenseTypeModal",
  props: ['expenseType'],
  data() {
    return {
      name: '',
      expense_category: '',
    }
  },
  watch: {
    expenseType() {
      this.name = this.expenseType && this.expenseType.name ? this.expenseType.name : '';
      this.expense_category = this.expenseType && this.expenseType.expense_category ? this.expenseType.expense_category.name : '';

    },
  },
  methods: {
    resetExpenseType() {
      Object.assign(this.$data, this.$options.data());
    }
  }
}
</script>

<style scoped>

</style>